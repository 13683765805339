import classNames from "classnames";
import { MemoInterface } from "@carglassgithub/memod-sdk";
import MemoCard from "../../organisms/memo-card-v2";

type Props = {
  memos: MemoInterface[];
  className?: string;
}

export default function TrendingMemos({ memos, className }: Props) {
  return (
    <div className={classNames(
      "flex flex-col gap-8 xl:gap-5",
      className,
    )}>
      <div>
        <h3 className="text-2xl font-semibold text-base-white">Memos</h3>
        <h4 className="text-xl leading-8 text-memod-label">Quick reads of compact wisdom</h4>
      </div>
      <div>
        {memos.map((memo, index) => (
          <div key={`tm-${memo.id}`} className="flex gap-4 xl:gap-6 group">
            <div className="text-[42px] leading-[1.3] text-memod-grey-based font-semibold basis-7 shrink-0">
              {index + 1}
            </div>
            <MemoCard memo={memo} variant="simple" />
          </div>
        ))}
      </div>
    </div>
  );
}