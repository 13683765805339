import classNames from "classnames";
import { translate } from "@locales";
import Icon from "@components/atoms/icon";

interface Props {
  className?: string;
}

export default function DownloadAppMobile (props: Props) {
  return (
    <div className={classNames(
      "bg-base-black text-base-white px-4 py-3 text-sm flex justify-between",
      props.className
    )}>
      <div className="flex gap-3">
        <Icon.MiniMemod size={33} />
        <div>
          <h2 className="font-semibold mb-1">
            {translate("download-app.mobile.header")}
          </h2>
          <h3>
            {translate("download-app.mobile.subheader")}
          </h3>
        </div>
      </div>
      <a
        href="https://apps.apple.com/us/app/memod-quick-reads-to-inspire/id1590241623"
        className="bg-base-white hover:bg-base-white/70 text-base-black self-start leading-4 px-3.5 py-2 rounded-full"
        rel="nofollow"
      >
        {translate("download-app.open")}
      </a>
    </div>
  );
}