import { useEffect, useState } from "react";
import type { GetServerSideProps } from "next";
import { useAtomValue, useStore } from "jotai";
import { useRouter } from "next/router";
import Image from "next/image";
import { isIOS } from "react-device-detect";
import { sdkv1 } from "@models/api";
import { UserAtom } from "@models/state";
import {
  AUTH_STATUS,
  AuthModalAtom,
} from "@models/state/atoms/auth-modal.atom";
import { ssrInjector } from "@models/interactions/ssr-injector";
import Button from "@components/atoms/button";
import CommonLayout from "@components/templates/common-layout";
import DownloadAppQr from "@components/molecules/download-app-qr";
import TrendingMemos from "@components/molecules/trending-memos";
import TrendingCollections from "@components/molecules/trending-collections";
import CommonHeader from "@components/organisms/common-header";
import CommonFooter from "@components/organisms/common-footer";
import DownloadAppMobile from "@components/molecules/download-app-mobile";
import NavbarLoggedOut from "@components/molecules/navbar-logged-out";
import classNames from "classnames";
import {
  BoardsInterface as CollectionInterface,
  MemoInterface,
} from "@carglassgithub/memod-sdk";
import Head from "next/head";

interface Props {
  isLogged: boolean;
  trendingMemos: MemoInterface[];
  trendingCollections: CollectionInterface[];
}

function useHome() {
  const user = useAtomValue(UserAtom);
  const router = useRouter();
  const store = useStore();
  const isClient = typeof window !== "undefined";
  const isHomePage = ["/", "/home", "/about-us", "/testimonials"].includes(
    router.asPath
  );
  const [size, setSize] = useState(0);

  const [trendingMemos, setTrendingMemos] = useState<MemoInterface[]>([]);
  const [trendingCollections, setTrendingCollections] = useState<
    CollectionInterface[]
  >([]);

  function resize() {
    setSize(window.innerWidth);
  }

  useEffect(() => {
    if (isClient && user && user?.welcome === 1) {
      router.push("/home/following");
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      const [memosResponse, collectionsResponse] = await Promise.allSettled([
        sdkv1.http.http.get(`/feeds-v2?limit=3`),
        sdkv1.http.http.get(`/feeds-v2?type=collection&limit=3`),
      ]);

      if (memosResponse.status === "fulfilled") {
        setTrendingMemos(memosResponse.value.data);
      }

      if (collectionsResponse.status === "fulfilled") {
        setTrendingCollections(collectionsResponse.value.data);
      }
    })();

    const isClient = typeof window !== "undefined";

    if (isClient) {
      resize();
      window.addEventListener("resize", resize);

      return () => window.removeEventListener("resize", resize);
    }
  }, []);

  return {
    models: {
      size,
      isHomePage,
      trendingMemos,
      trendingCollections,
    },
    operations: {
      store,
    },
  };
}

export default function Home(props: Props) {
  const { models, operations } = useHome();

  return (
    <CommonLayout
      className={classNames(
        "items-stretch bg-composer-bg md:px-9 lg:items-stretch xl:mx-auto xl:max-w-[1162px] xl:flex-col xl:px-9",
        {
          "!pt-[132px]": isIOS && models.isHomePage && models.size < 720,
        }
      )}
      topClassName="bg-composer-bg"
      Header={
        <div className="fixed z-50 w-full">
          <DownloadAppMobile
            className={classNames({
              "md:hidden": isIOS,
              hidden: !isIOS,
            })}
          />
          <CommonHeader
            search
            hiddeBack
            showHomeLinks
            autocomplete
            className="bg-composer-bg"
          />
        </div>
      }
      Footer={<CommonFooter />}
    >
      <Head>
        <link rel="canonical" href="https://memod.com" />
      </Head>
      <div className="flex flex-col py-10">
        <section className="flex flex-col gap-3">
          <h1 className="font-ZillaSlab text-[32px] font-semibold italic leading-[48px] text-base-white md:text-5xl md:leading-[64px] xl:text-[64px] xl:leading-[76px]">
            Wisdom distilled in 10 bullet points
          </h1>
          <h3 className="text-memod-grey-secondary md:text-2xl">
            🚀 Rapid focus: Read in 3 mins or less | 💡 1,000s of insights | 💰
            100% free
          </h3>
          <h2 className="text-xl leading-8 text-memod-white-base md:text-2xl">
            Discover timeless ideas from the world’s greatest thinkers
          </h2>
          <Button.Pill
            className="mt-[26px] self-start"
            onClick={() => {
              operations.store.set(AuthModalAtom, {
                mode: AUTH_STATUS.SIGN_UP,
                open: true,
              });
            }}
          >
            Join Memo’d
          </Button.Pill>
        </section>
      </div>
      <div className="flex flex-col gap-6 pb-5 pt-10 md:flex-row md:justify-between">
        <TrendingMemos
          memos={models.trendingMemos}
          className="md:basis-[45%] xl:basis-1/2"
        />
        <TrendingCollections
          collections={models.trendingCollections}
          className="md:basis-[45%] xl:basis-1/3"
        />
      </div>
      <div className="flex flex-col py-16">
        <div className="flex flex-col gap-5 rounded-[20px] bg-memod-button p-4 text-base-white md:flex-row md:gap-0 md:p-8">
          <div className="flex flex-col gap-3 md:grow">
            <h3 className="text-2xl font-semibold md:text-[28px] md:leading-9">
              Memo’d: Powerful ideas made clear
            </h3>
            <p className="md:text-xl md:leading-8">
              Discover bite-sized guides, expert tips, and concise summaries in
              minutes
            </p>
            <div className="md:text-xl md:leading-8">
              <h5>Sign up to:</h5>
              <ul className="list-disc pl-6">
                <li>Publish your own Memos</li>
                <li>Save, curate and share inspiration quickly</li>
                <li>Enjoy a customized feed of insights</li>
              </ul>
            </div>
            <nav className="flex pt-5 md:pt-7">
              <NavbarLoggedOut
                className="!px-6"
                onLogIn={() => {
                  operations.store.set(AuthModalAtom, {
                    mode: AUTH_STATUS.LOGIN,
                    open: true,
                  });
                }}
                onSignUp={() => {
                  operations.store.set(AuthModalAtom, {
                    mode: AUTH_STATUS.SIGN_UP,
                    open: true,
                  });
                }}
              />
            </nav>
          </div>
          <div className="md:shrink-0 md:basis-2/5 xl:basis-1/3">
            <Image
              className="aspect-[467/372] h-full max-h-[372px] w-full max-w-[467px] object-contain"
              src="https://v2.memod.com/_nuxt/img/profiles_big.1f1d233.png"
              alt="Authors Collage"
              width={467}
              height={372}
            />
          </div>
        </div>
      </div>
      <DownloadAppQr />
    </CommonLayout>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  ...context
}) => {
  const token = req.headers.cookie?.match(
    /\b(?:token|KANVAS_SDK_TOKEN)=([^;]+)(?:;|$)/
  )?.[1]!;

  if (token) {
    return {
      redirect: {
        permanent: false,
        destination: "/home/following",
      },
    };
  }

  const isLogged = Boolean(ssrInjector(context));
  let trendingMemos: MemoInterface[] = [];
  let trendingCollections: CollectionInterface[] = [];

  // res.setHeader(
  //   "Cache-Control",
  //   "public, s-maxage=10, stale-while-revalidate=59"
  // );

  return {
    props: {
      isLogged: isLogged ?? null,
    },
  };
};
