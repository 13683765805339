import classNames from "classnames";
import { BoardsInterface as CollectionInterface } from "@carglassgithub/memod-sdk";
import CollectionCard from "@components/organisms/collection-card-v2";

type Props = {
  collections: CollectionInterface[];
  className?: string;
};

export default function TrendingCollections({ collections, className }: Props) {
  return (
    <div className={classNames("flex flex-col gap-4", className)}>
      <div>
        <h3 className="text-2xl font-semibold text-base-white">Collections</h3>
        <h4 className="text-xl leading-8 text-memod-label">
          Curated knowledge guides
        </h4>
      </div>
      <div className="flex flex-col gap-3">
        {collections.map((collection, index) => (
          <CollectionCard
            key={`tc-${index}`}
            variant="simple"
            collection={collection}
          />
        ))}
      </div>
    </div>
  );
}
