import classNames from "classnames";
import Image from "next/image";
import QR from "public/img/memod/download-test-flight.png";

type Props = {
  className?: string;
};

export default function DownloadAppQr() {
  return (
    <div className="fixed bottom-20 right-6 z-50 hidden w-36 flex-col items-center rounded-xl bg-composer-bg py-2 xl:flex">
      <span className="mb-1 text-center text-sm text-memod-green-base">
        Get the Memo'd App
      </span>

      <p className="mb-2 text-center text-xs text-white">
        For the best experience,
        <br />
        download our free app by scanning the QR code
      </p>

      <Image
        src={QR}
        alt="QR Code"
        width={120}
        height={120}
        className="h-[120px] w-[120px]"
      />
    </div>
  );
}

export function QRCodeFooter({ className }: Props) {
  return (
    <div className={classNames("flex gap-3", className)}>
      <div className="flex flex-col gap-1">
        <span className="text-xl leading-8 text-base-white">
          Get the Memo'd App
        </span>
        <p className="text-sm text-memod-label">
          For the best experience, <br />
          download our free app by scanning the QR code
        </p>
      </div>
      <Image
        src={QR}
        alt="QR Code"
        width={64}
        height={64}
        className="h-[64px] w-[64px]"
      />
    </div>
  );
}
