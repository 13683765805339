
export async function SubscribeMailchip(emailInput: string) {
	const response = await fetch("/api/mailchimp", {
		method: "POST",
		headers: {
			Accept: "application.json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(emailInput),
	})
	return response;
}
